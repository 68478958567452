<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="' create-form'"
    v-if="getPermission('visit:create')"
  >
    <template v-slot:body>
      <div
        class="product-create create-form"
        v-if="getPermission('error-code:create')"
      >
        <v-form
          ref="productForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="onSubmit"
        >
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-container fluid class="white-background">
                <div class="p-5 pb-0">
                  <v-row>
                    <v-col cols="6" class="pt-0">
                      <h1 class="form-title d-flex">
                        <template v-if="productId">Update</template>
                        <template v-else>Create</template> Error Code

                        <Barcode
                          v-if="productCreate && productCreate.barcode"
                          class="my-0 mt-1 mb-0 mr-1 ml-2"
                          :barcode="productCreate.barcode"
                        ></Barcode>
                      </h1>
                    </v-col>
                    <v-col cols="6" class="pt-0 text-right">
                      <v-btn
                        :disabled="!formValid || formLoading"
                        :loading="formLoading"
                        class="mx-2 custom-bold-button text-white"
                        color="cyan"
                        v-on:click="onSubmit"
                      >
                        <template v-if="productId">Update</template>
                        <template v-else>Save</template> Error Code
                      </v-btn>
                      <v-btn
                        v-on:click="goBack"
                        :disabled="formLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        outlined
                        >Cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>

                <ProductCreate
                  :productId="productId"
                  :barcodeData="barcode"
                  :updateMode="updateMode"
                  :productCreateData="productCreate"
                  v-on:saveProduct="updateProduct"
                ></ProductCreate>
              </v-container>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductMixin from "@/core/plugins/product-mixin";
import { ProductBrandEventBus } from "@/core/lib/product.brand.lib";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";
import ProductCreate from "@/view/pages/error-code/partials/Create.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import Barcode from "@/view/pages/partials/Barcode.vue";
//import { map } from "lodash";
export default {
  mixins: [CommonMixin, ProductMixin],
  name: "product-create",
  title: "Create Product",
  data() {
    return {
      isCustomerCreated: false,
      files: [
        {
          file: null,
          name: null,
        },
      ],
      barcode: null,
      barcodeDialog: false,
      updateMode: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      productCreate: null,
      productId: null,
      duplicateId: null,
      docIds: [],
    };
  },
  components: {
    CreateUpdateTemplate,
    ProductCreate,
    Barcode,
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    // getFiles(param) {
    //   console.log(param);
    //   this.files = param;
    // },
    updateProduct(param) {
      this.productCreate = param;
    },
    getProduct(productId) {
      const _this = this;

      return new Promise((resolve, reject) => {
        if (productId && productId > 0) {
          _this.$store
            .dispatch(GET, { url: "error-code/" + productId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },
    createWarrantyEntity() {
      const _this = this;
      return new Promise((resolve) => {
        let warrantyFormData = _this.lodash.compact(
          _this.lodash.map(_this.productCreate.warranty, function (param) {
            if (param.field && param.value) {
              return param;
            }
          })
        );

        if (_this.lodash.isEmpty(warrantyFormData) === false) {
          _this.formLoading = true;
          _this.$store
            .dispatch(POST, {
              url: "product/" + _this.productId + "/entity",
              data: {
                type: 1,
                entities: warrantyFormData,
              },
            })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.formLoading = false;
            });
        } else {
          resolve(true);
        }
      });
    },
    onSubmit() {
      const _this = this;
      if (!_this.$refs.productForm.validate()) {
        return false;
      }
      if (
        Number(this.productCreate.selling_cost) <
        Number(this.productCreate.threshold_price)
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Selling cost / Unit cost Should be greater than Threshold Price."
          )
        );
        return false;
      }
      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "error-code";

      if (_this.productId && _this.productId > 0) {
        requestType = PUT;
        requestURL = "error-code/" + _this.productId;
      }

      //let fileArray = [];
      // var formData = new FormData();
      // formData.append('project', _this.productCreate );
      // for (let i = 0; i < this.files.length; i++) {
      //   if (this.files && this.files[i] && this.files[i].file) {
      //   //  fileArray.push(['file'=>this.files[i].file]));
      //     // fileArray['file'][] = this.files[i].file;
      //     // fileArray['name'][] = this.files[i].name;
      //     // fileArray['suffix'][] = this.files[i].suffix;
      //     formData.append(`file[${i}][file]`, this.files[i].file);
      //     formData.append(`file[${i}][name]`, this.files[i].name);
      //     console.log(this.files[i].file);
      //     console.log(this.files[i].name);
      //     formData.append('abcd', 'shell');

      //   }
      // }
      //   console.log(formData);
      //   // _this.productCreate.file[i]["file"]=  this.files[i].file;
      //   // _this.productCreate.file[i]["name"] =  this.files[i].name;
      //   _this.productCreate.file =  formData;
      // //_this.productCreate.files = ;
      // // for (let i = 0; i < this.files.length; i++) {
      // //   if (this.files && this.files[i] && this.files[i].file) {
      // //     _this.productCreate.append(`file[${i}][file]`, this.files[i].file);
      // //     _this.productCreate.append(`file[${i}][name]`, this.files[i].name);
      // //   }
      // // }

      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.productCreate })
        .then(async (response) => {
          _this.productId = response.data ? response.data.id : null;

          await _this.createWarrantyEntity();
          _this.isCustomerCreated = true;
          _this.$router.push(
            _this.getDefaultRoute("error-code", {
              query: {
                status: "all",
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getProduct(_this.productId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Error Code", route: "error-code" },
              { title: "Update" },
            ]);

            _this.productCreate = {
              profile: response.profile ? response.profile : null,
              name: response.name,
              /*    quantity: response.quantity, */
              reference: response.reference,
              sku: response.sku,
              upc: response.upc,
              ean: response.ean,
              isbn: response.isbn,
              images: response.images,
              product_images: response.product_images,
              product_attachments: response.product_attachments,
              part_number: response.part_number,
              hsn_code: response.hsn_code,
              serial_number: response.serial_number,
              description: response.description,
              directory: response.directory,
              supplier: response.supplier ? response.supplier.id : null,
              brand: response.brand ? response.brand : null,
              barcode: response.barcode ? response.barcode : null,
              manufacturer: response.manufacturer
                ? response.manufacturer
                : null,
              category: response.category ? response.category : null,
              unit: response.unit ? response.unit : null,
              tax: response.tax ? response.tax.id : null,
              tax_exemption: response.tax_exemption
                ? response.tax_exemption.id
                : null,
              accounting: response.accounting,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,

              project_price: response.project_price,
              threshold_price: response.threshold_price,
              commission_rate: response.commission_rate,
              website_url: response.website_url,

              length: response.length,
              model: response.model,
              width: response.width,
              height: response.height,
              weight: response.weight,
              taxable: response.is_taxable,
              sellable: response.is_sellable,
              confirmed: response.is_confirmed,
              track_batch: response.track_batch,
              manage_stock: response.manage_stock,
              reorder_level: response.reorder_level,
              initial_stock: response.initial_stock,
              charges: response.charges,
              warranty_type: response.duration_time,
              warranty_duration: response.warranty_check,

              /* warranty: _this.lodash.compact(
                _this.lodash.map(response.warranty_data, function (param) {
                  if (param.field && param.value) {
                    return {
                      id: param.id,
                      field: param.field,
                      value: param.value,
                    };
                  }
                })
              ), */

              priceList: _this.lodash.compact(
                _this.lodash.map(response.product_pricelogs, function (row) {
                  return {
                    id: row.id ? row.id : null,
                    unit_price: row.unit_price ? row.unit_price : 0,
                    selling_cost: row.sale_price ? row.sale_price : 0,
                    discount_price: row.discount_price ? row.discount_price : 0,
                    start_date: row.start_date,
                    end_date: row.end_date,
                  };
                })
              ),
              suppliers: _this.lodash.compact(
                _this.lodash.map(response.product_supplier, function (row) {
                  return {
                    id: row.id,
                    supplier_id: row.supplier,
                    part_number: row.part_number,
                    supplier_price: row.supplier_price,
                  };
                })
              ),
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    loadDuplicateContent() {
      const _this = this;
      _this
        .getProduct(_this.duplicateId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.productCreate = {
              profile: response.profile ? response.profile : null,
              barcode: this.barcode,
              name: response.name,
              /*  quantity: response.quantity, */
              reference: response.reference,
              sku: response.sku,
              upc: response.upc,
              ean: response.ean,
              isbn: response.isbn,
              images: response.images,
              product_images: [], //response.product_images,
              part_number: response.part_number,
              hsn_code: response.hsn_code,
              description: response.description,
              project_price: response.project_price,
              serial_number: response.serial_number,
              threshold_price: response.threshold_price,
              directory: response.directory,
              supplier: response.supplier ? response.supplier.id : null,
              brand: response.brand ? response.brand : null,
              manufacturer: response.manufacturer
                ? response.manufacturer
                : null,
              category: response.category ? response.category : null,
              unit: response.unit ? response.unit : null,
              tax: response.tax ? response.tax.id : null,
              tax_exemption: response.tax_exemption
                ? response.tax_exemption.id
                : null,
              accounting: response.accounting,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,
              length: response.length,
              width: response.width,
              height: response.height,
              weight: response.weight,
              taxable: response.is_taxable,
              sellable: response.is_sellable,
              confirmed: response.is_confirmed,
              initial_stock: response.initial_stock,
              track_batch: response.track_batch,
              manage_stock: response.manage_stock,
              reorder_level: response.reorder_level,
              charges: response.charges,
              website_url: response.website_url,
              warranty_type: response.duration_time,
              warranty_duration: response.warranty_check,
              /* warranty: _this.lodash.compact(
                _this.lodash.map(response.warranty_data, function (param) {
                  if (param.field && param.value) {
                    return {
                      id: null,
                      field: param.field,
                      value: param.value,
                    };
                  }
                })
              ), */
              priceList: _this.lodash.compact(
                _this.lodash.map(response.product_pricelogs, function (row) {
                  return {
                    id: row.id ? row.id : null,
                    unit_price: row.unit_price ? row.unit_price : 0,
                    selling_cost: row.sale_price ? row.sale_price : 0,
                    discount_price: row.discount_price ? row.discount_price : 0,
                    start_date: row.start_date,
                    end_date: row.end_date,
                  };
                })
              ),
              suppliers: _this.lodash.compact(
                _this.lodash.map(response.product_supplier, function (row) {
                  return {
                    id: row.id,
                    supplier_id: row.supplier,
                    part_number: row.part_number,
                    supplier_price: row.supplier_price,
                  };
                })
              ),
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  created() {
    const _this = this;
    _this.productId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Error Code", route: "error-code" },
      { title: "Create" },
    ]);

    if (
      _this.$route.name == "admin.error-code.update" ||
      _this.$route.name == "engineer.error-code.update"
    ) {
      _this.updateMode = true;

      if (_this.productId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }

    ProductBrandEventBus.$on("refress-brand", function () {
      this.getProduct(_this.productId);
    });
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.isCustomerCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
